var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-group shadow" },
    _vm._l(_vm.matchedItems, function(item, id) {
      return _c("vue-bootstrap-typeahead-list-item", {
        key: id,
        attrs: {
          data: item.data,
          "html-text": _vm.highlight(item.text),
          "background-variant": _vm.backgroundVariant,
          "text-variant": _vm.textVariant
        },
        nativeOn: {
          click: function($event) {
            return _vm.handleHit(item, $event)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "suggestion",
              fn: function(ref) {
                var data = ref.data
                var htmlText = ref.htmlText
                return _vm.$scopedSlots.suggestion
                  ? [
                      _vm._t("suggestion", null, null, {
                        data: data,
                        htmlText: htmlText
                      })
                    ]
                  : undefined
              }
            }
          ],
          null,
          true
        )
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }